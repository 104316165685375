import { defineLocale } from "aeria-ui";
import { Reasons } from "../../api/src/utils/spreadsheetValidator"

const reasonsLocalization: Record<Reasons, Lowercase<string>> = {
    empty: "vazio",
    invalid_date: "data inválida",
    invalid_document: "documento inválido",
    invalid_integer: "número inteiro inválido",
    invalid_number: "número inválido",
    not_text: "não é um texto",
    invalid_format: "formato inválido"
}

export const ptBr = defineLocale({
    business: ["empresa", "empresas"],
    customer: ["cliente", "clientes"],
    failedorder: ["pedido problemático", "pedidos problemáticos"],
    geolocation: ["endereço", "endereços"],
    integration: ["integração", "integrações"],
    integrations: "integrações",
    lateorder: ["pedido em atraso", "pedidos em atraso"],
    order: ["pedido", "pedidos"],
    orderreview: ["revisão de pedido", "revisões de pedido"],
    platform: ["plataforma", "plataformas"],
    product: ["produto", "produtos"],
    products: "produtos",
    productplan: ["plano", "planos"],
    productPlan: ["plano", "planos"],
    reshipment: ["reenvio", "reenvios"],
    shipment: ["remessa", "remessas"],
    shippingprofile: ["perfil de logística", "perfis de logística"],
    shippingProfile: ["perfil de logística", "perfis de logística"],
    shippingprovider: ["transportadora", "transportadoras"],
    shippingService: ["serviço de entrega", "serviços de entrega"],
    shippingtable: ["tabela de frete", "tabelas de frete"],
    created: "criado",
    authorized: "autorizado",
    waiting_payment: "pendente",
    paid: "pago",
    handling_products: "em preparação",
    on_carriage: "a caminho",
    delivered: "entregue",
    cancelled: "cancelado",
    refused: "recusado",
    invoiced: "nota fiscal emitida",
    shipment_exception: "exceção na entrega",
    in_review: "em revisão",
    sent: "enviado",
    pending: "pendente",
    settled: "resolvido",
    unsettled: "não resolvido",
    shipping_cancel: "Cancelamento de envio",
    address_change: "Alteração de envio",
    quantity_change: "Alteração de quantidade",
    product_change: "Alteração de produto",
    modality_change: "Alteração de modalidade",
    not_rejected: "Não foi rejeitado",
    already_processed: "Já havia sido aferido pelo sistema",
    already_posted: "Já havia sido postado",
    already_shipping: "Passou por troca e já está em transporte",
    none: "Nenhum",
    problematic: "problemático",
    healthy: "saudável",
    name: "nome",
    document: "documento",
    content_description: "Conteúdo",
    height: "Altura",
    width: "Largura",
    weight: "Peso",
    declared_value: "Valor declarado",
    amount: "Valor",
    receiver_name: "Nome do destinatário",
    receiver_address: "Endereço do destinatário",
    receiver_district: "Bairro do destinatário",
    receiver_city: "Cidade do destinatário",
    receiver_document: "CPF do destinatário",
    receiver_number: "Número do endereço destino",
    receiver_complement: "Complemento do destinatário",
    receiver_state: "Estado do destinatário (UF)",
    receiver_email: "Email de contato",
    receiver_phone: "Telefone",
    address: "Endereço",
    district: "Bairro",
    city: "Cidade",
    number: "Número",
    complement: "Complemento",
    state: "Estado",
    debit: "débito",
    credit: "crédito",
    date_min: "Data mínima",
    date_max: "Data máxima",
    orderpaid: "pago",
    orderupdated: "atualizado",
    ordertoken: "token",
    mapping: "mapeamento",
    organization: "organização",
    profile: "perfil",
    signout: "Sair",
    "action.signout": "Sair",
    copy_activation_link: "Copiar link de ativação",
    token: ["token", 'tokens'],
    plans: "planos",
    quantity: "quantidade", 
    customer_name: "nome do cliente",
    customer_document: "documento do cliente",
    customer_email: "email do cliente",
    customer_phone: "número do cliente",
    street: "rua",
    zipcode: "CEP",
    payment_type: "forma de pagamento",
    date_created: "data de criação",
    retained_value: "valor retido",
    plan: ["plano", "planos"],
    commission_value: "valor da comissão",
    commission_type: "tipo da comissão",
    fixed: "fixo",
    percentage: "porcentagem",
    product_name: "nome do produto",
    plan_name: "nome do plano",
    product_code: "código do produto",
    plan_code: "código do plano",
    product_type: "Tipo de produto",
    devolution: "devolução",
    created_at: "Criado em",
    updated_at: "Atualizado em",
    provider: "Provedor",
    picture: "Imagem",
    phone_number: "número de telefone",
    picture_file: "arquivo de imagem",
    details: "detalhes",
    message: "mensagem",
    owner: "dono",
    context: "contexto",
    locked: "trancado",
    code: "código",
    was_configured: "foi configurado",
    key: "chave",
    franchise: "franquia",
    identifier: "identificador",
    file: "arquivo",
    files: "arquivos",
    copy_redefine_password_link: "Copiar link de redefinição de senha",
    shippingTable: "Tabela de envio",
    producer: "produtor",
    producerSupport: "produtor", 
    customerSupport: "suporte ao cliente", 
    support: "suporte",
    logistic: "logística",
    guest: "convidado",
    manager: "gerente",
    root: "superusuário",
    roles: "cargos",
    active: "ativo",
    available_for_pickup: "disponível para retirada",
    redefine_password: "Redefinir senha",
    platform_tracking_update: "Envio de rastreio para plataforma",
    status_updated: "Status atualizado",
    shipment_dispatched: "Encomenda despachada",
    ...reasonsLocalization
});