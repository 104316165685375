<script setup lang="ts">
const geolocationStore = useStore('geolocation')
definePage({
  meta: {
    title: 'Visualizar localização',
    icon: 'address-book'
  },
  props: true
})

const props = defineProps<{
  id: string
  section?: string
}>()

geolocationStore.$actions.get({ filters: { _id: props.id }})  
</script>

<template>
<edit-history :collectionStore="geolocationStore"/>
</template>
