<script setup lang="ts">
import Collapsible from './_collapsible.vue'
import { MirrorDescriptions } from '../../../../.aeria-ui/aeria-sdk';
import { useI18n } from 'aeria-ui';
import { AccessCondition, UserRole } from '@aeriajs/types';
import { routes } from '../routes';
const { t } = useI18n()

definePage({
    meta: {
        title: "Documentação",
        icon: "article"
    }
})

const orderStore = useStore('order')
const productStore = useStore('product')
const userStore = useStore('user')

type ActionExplanation = {
    [TCollection in keyof Collections]?: {
        [TAction in (
      keyof (
        'individualActions' extends keyof MirrorDescriptions[TCollection]
          ? MirrorDescriptions[TCollection]['individualActions']
          : string
      )
    ) | (string & {})]?: {
            description?: string,
            roles?: AccessCondition
        }
    }
}

console.log(productStore.individualActions);

/** You put the original names of the collection and it's actions and the function 
 * getActionDescriptorTranslated will automatically translate it, if the action
 * is not there just put the translated name of it */
const actionDescriptor: ActionExplanation = {
    order: {
        spawnEdit: {
            roles: routes["/order/insert"].POST.roles
        },
        viewOrder: {
            description: "Visualiza uma página com informações expandidas do pedido, com a parte de cima da página possuindo seções permitindo a visualização detalhada de dados como a localização do cliente.",
        },
        notify: {
            description: "Notifica o produtor que está ligado ao pedido que o pedido precisa urgentemente de revisão manual e o tempo que ele está sem resolução.",
            roles: routes["/order/notifyAboutOrder"].POST.roles
        },
        makeReshipment: {
            description: "Redireciona o usuário á aba de postagem manual com todos os dados do pedido e a opção de reenvio marcada.",
        },
        freeOrders: {
            description: "Caso o pedido não tenha cumprido o valor mínimo do valor configurado nos planos que o pedido possui o sistema irá ignorá-lo" +
                         " na postagem automática, essa ação impede isso fazendo com que o sistema poste mesmo com essa condição. (NÃO IMPLEMENTADO AINDA)",
            roles: routes["/order/freeOrders"].POST.roles
        },   
        "Reportar": {
            description: "Exporta os pedidos filtrados no filtro pra um arquivo CSV.",
            roles: routes["/order/report"].POST.roles
        },
        "Postagem manual": {
            description: "Permite a postagem manual para pedidos não recebidos por webhook ou puxados automaticamente, e o reenvio manual de pedidos problemáticos.",
            roles: routes["/shippingTable/manualPost"].POST.roles
        },
        "Importar planilha": {
            description: "Permite a importação de planilha de pedidos, a planilha deve ter as colunas de acordo com a ordem " + 
            "configurada no painel 'Ordem das colunas', o status deve estar no formato da arco ao invés de traduzido e o documento do cliente não deve ter pontuação." +
            " Clicando em importar será mostrado o resultado da validação dos dados, certifique-se que se tiver erros nenhum de fato atrapalhe no envio do pedido.",
            roles: routes["/integration/importSpreadsheet"].POST.roles
        }
    },
    integration: {
        "Buscar faixa de pedidos": {
            description: "Busca pedidos em todas as plataformas da organização na faixa de data selecionada.",
            roles: routes["/order/getSpan"].POST.roles
        },
        getSpan: {
            description: "Busca de forma retroativa todos os pedidos da integração no intervalo selecionado (Obs: só funciona nas que tenham implementado a busca de pedidos na API).",
            roles: routes["/order/getSpan"].POST.roles
        },
        "Inserir/editar": {
           roles: routes["/integration/insert"].POST.roles
        },
        "Remover": {
            roles: routes['/integration/remove'].POST.roles
        }
    },
    shipment: {
        "Gerar etiquetas": {
            roles: routes['/shipment/make'].POST.roles
        },
        "Atualizar rastreios": {
            roles: routes['/shipment/updateSpanTracking'].POST.roles
        }
    },
    product: {
        configure: {
            description: "Permite a configuração do tipo do plano ou produto, produtos/planos do tipo digital vão ser ignorados na criação automática de encomendas.",
            roles: productStore.individualActions.find(individualAction => individualAction.action === "configure").roles
        }
    },
    business: {
        "Inserir/editar": {
            roles: routes['/business/insert'].POST.roles
        },
        "Remover": {
            roles: routes['/business/remove'].POST.roles
        },
    },
    shippingProfile: {
        "Inserir/editar": {
            roles: routes['/shippingProfile/insert'].POST.roles
        },
        "Remover": {
            roles: routes['/shippingProfile/remove'].POST.roles
        },
        updateServices: {
            roles: routes['/shippingProfile/updateServices'].POST.roles
        }
    },
    shippingService: {
        "Inserir/editar": {
            roles: routes['/shippingService/insert'].POST.roles
        },
        "Remover": {
            roles: routes['/shippingService/remove'].POST.roles
        },
    },
    shippingTable: {
        "Inserir/editar": {
            roles: routes['/shippingTable/insert'].POST.roles
        },
        "Remover": {
            roles: routes['/shippingTable/remove'].POST.roles
        },
        unload: {
            roles: routes['/shippingTable/unload'].POST.roles
        },
        load: {
            roles: routes['/shippingTable/load'].POST.roles
        },
        "Simular frete": {
            roles: routes['/shippingTable/simulate'].POST.roles
        }
    },
    organization: {
        "Inserir/editar": {
            roles: routes['/organization/insert'].POST.roles
        }
    }
}

const getActionDescriptorTranslated = () => {
    return Object.entries(actionDescriptor).map(([collectionName, value]) => {
        const store = useStore(collectionName as keyof Collections)

        const actionInfo = Object.fromEntries(Object.entries(value).map(([actionName, value]) => {
            const actionLabel = store.rawDescription.individualActions && actionName in store.rawDescription.individualActions ? 
            store.rawDescription.individualActions[actionName]?.label :
            actionName
            
            return [actionName, { 
                label: actionLabel, 
                value
            }]
        }))
        
        return [t(collectionName, { capitalize: true }), {
            icon: store.rawDescription.icon,
            actionInfo
        }]
    }) as any
}

const translatedRole = (role: UserRole, capitalize: boolean = false) => t(role, { capitalize })
</script>

<template>
    <div class="
    tw-flex 
    tw-flex-col 
    [&>*]:tw-border-2 
    [&>*]:tw-border-[var(var(--theme-radius-large))]
    tw-space-y-[-1pt]
    ">
    <collapsible
    title="Funcionamento das integrações"
    isTopic>
    <div class="[&>p]:tw-mb-2">
        <p>
            Possuindo a plataforma no sistema, as integrações podem ser criadas na seção de integrações, 
                ao criar uma integração é possível copiar a URL de postback (a qual a arco irá receber os pedidos da integração via webhook)
                clicando em editar ou visualizar nos três pontos do lado direito dela, também é necessário colocar a chave secreta
                do postback pra que o sistema verifique se a informação recebida é dessa integração, caso contrário ele irá retornar erro
                nos webhooks.
            </p>
            <p>
                Integrações aonde é possível fazer a busca de pedidos por API e isso esteja implementado na arco, também
                é necessário colocar as chaves necessárias pra autenticação ao buscar os pedidos.
                Caso a arco identifique algum erro crítico na integração seja de chaves erradas ou formato desconhecido ao receber pedidos 
                da integração ela será desativada automaticamente para evitar sobrecarga do sistema.
            </p>
            <p>
                Integrações que retornam algum campo indicando se um produto/plano é digital a arco irá automaticamente
                ignorar os pedidos com esses planos, mas pras que não retornam essa especificação é possível
                criar um filtro de planos na integração clicando em editar.
            </p> 
        </div>
        </collapsible>
    
        <collapsible
        title="Funcionamento dos pedidos"
        isTopic>
        <div class="[&>p]:tw-mb-2">
            <p>
                Pedidos são obtidos atráves de três maneiras atualmente, pela integração através de webhook ou API, pela postagem manual
                e pela importação de planilha, pedidos com problemas ao serem enviados irão para a seção de "problemáticos" em pedidos.
            </p>
            <p>
                Problemas em pedidos geralmente são: campos de endereço errado, endereço inexistente, documento errado, conteúdo errado, etc.
                Caso o problema seja no endereço é possível editá-lo na própria aba de pedidos clicando nos três pontos do lado e em editar,
                se o problema for no cliente basta clicar no nome dele na aba de pedidos pra redirecionar á pagina do cliente aonde você pode
                editar os campos da mesma forma.
            </p>
            <p>
                Caso algum pedido não foi puxado pela integração é possível usar a postagem manual pra criar e automaticamente postar esse pedido,
                também é possível importar uma planilha de pedidos que pode ser feita manualmente ou exportada no site da plataforma.
            </p>
        </div>
        </collapsible>
    
        <collapsible
        title="Funcionamento das remessas"
        isTopic>
        <div class="[&>p]:tw-mb-2">
            <p>
                Remessas são criadas em lote automaticamente pela Arco todo dia, caso isso não aconteça por alguma falha no sistema ou esteja demorando
                o botão "Gerar etiquetas" irá fazer isso no instante em que você enviar a tarefa e caso mesmo assim não crie é por que os pedidos ou 
                o sistema está com algum problema.
                A Arco também notifica o rastreio de todos os pedidos pras suas plataformas todo dia, caso isso também não aconteça ou 
                você queira agilizar pode usar o botão "Atualizar rastreios" que irá fazer isso nas remessas criadas 
                na faixa de data selecionada, ou nas opções de uma remessa o botão "Reenviar códigos de rastreio" que fará isso pra todos os pedidos
                dessa remessa.
            </p>
        </div>
        </collapsible>
        <collapsible title="Cargos" isTopic>
            <div class="tw-flex tw-flex-col tw-space-y-[-1.0rem]">
                <collapsible :title="translatedRole('root', true)" icon="user">
                    <div class="tw-ml-5">
                        <p class="tw-mt-0">
                            Papel destinado à staff para manutenção da arco, pode fazer tudo que for possível pela interface.
                        </p>    
                    </div>
                </collapsible>
                <collapsible :title="translatedRole('manager', true)" icon="user">
                    <div class="tw-ml-5">
                        <p class="tw-mt-0">
                            {{ translatedRole('manager', true) }} da organização, possui permissão para criar e modificar usuários no escopo da sua organização.
                        </p>    
                    </div>
                </collapsible>
                <collapsible :title="translatedRole('producerSupport', true)" icon="user">
                    <div class="tw-ml-5">
                        <p class="tw-mt-0">
                            O {{ translatedRole('producerSupport') }} pode acessar pedidos problemáticos, mas não pode:
                        </p>
                        <ul>
                            <li>Alterar o status (com exceção de cancelado)</li>
                            <li>Alterar o código de rastreio</li>
                            <li>Alterar o serviço de entrega</li>
                            <li>Alterar a transportadora</li>
                            <li>Importar planilhas de pedidos</li>
                            <li>Desvincular remessas</li>
                            <li>Realizar reenvios</li>
                        </ul>
                        <p>
                            Produto em pedidos: o {{ translatedRole('producerSupport') }} pode marcar um pedido como problemático, desde que não tenha código de rastreio. 
                            No entanto, ele não pode importar planilhas de pedidos, realizar o reenvio, desvincular remessas ou enviar o pedido para revisão,
                            sendo permitido apenas editar o endereço do pedido.
                            <br/>
                            Na seção de produtos, o {{ translatedRole('producerSupport') }} não pode editar ou configurar produtos.
                        </p>
                    </div>
                </collapsible>
                <collapsible :title="translatedRole('customerSupport', true)" icon="user">
                    <div class="tw-ml-5">
                        <p class="tw-mt-0">
                            Papel destinado ao suporte ao cliente.
                        </p>
                    </div>
                </collapsible>
                <collapsible :title="translatedRole('logistic', true)" icon="user">
                    <div class="tw-ml-5">
                        <p class="tw-mt-0">
                            Papel que garante acesso às funções relacionadas às etiquetas, ela pode:
                        </p>
                        <ul>
                            <li>Gerenciar remessas.</li>
                            <li>Editar pedidos.</li>
                            <li>Alterar status: cancelamento e devolução.</li>
                            <li>Realizar postagem manual.</li>
                            <li>Gerenciar reenvios.</li>
                        </ul>
                    </div>
                </collapsible>
            </div>
        </collapsible>
        <collapsible
        title="Ações"
        isTopic>
        <div class="tw-flex tw-flex-col tw-space-y-[-1rem]">
            <collapsible 
            class="tw-inline-block"
            v-for="[collectionLabel, value] in getActionDescriptorTranslated()" 
            :title="String(collectionLabel)"
            :icon="value.icon">
                <ul class="tw-inline-block">
                    <li v-for="[_actionName, action] in Object.entries(value.actionInfo)">
                        <div class="tw-flex tw-space-x-2">
                            <div>{{ action.label }}</div>
                            <div v-if="action.value.roles" class="tw-text-sm tw-flex">
                                <aeria-icon icon="users-three"/>
                                <div class="tw-text-[var(--theme-brand-color-shade-2)]">({{ action.value.roles.map(role => t(role, { capitalize: true })).join(", ") }})</div>
                            </div>
                        </div>
                        <p>{{ action.value.description }}</p>
                    </li>
                </ul>
            </collapsible>
        </div>
    </collapsible>
        
    <collapsible
        title="Tarefas automáticas"
        isTopic>
            <dl class="tw-ml-5 [&>dd]:tw-text-[10pt] [&>dd]:tw-mb-2 tw-flex tw-flex-col tw-space-y-2">
                <dt><aeria-icon icon="tag-simple">Salvamento de pedidos recebidos por webhook</aeria-icon></dt>
                <dd><aeria-icon icon="clock">De 5 em 5 minutos</aeria-icon></dd>
    
                <dt><aeria-icon icon="tag-simple">Postagem de pedidos prontos pra encomenda</aeria-icon></dt>
                <dd><aeria-icon icon="clock">De 4 em 4 horas todos os dias</aeria-icon></dd>
    
                <dt><aeria-icon icon="tag-simple">Envio de informação de rastreio pra clientes</aeria-icon></dt>
                <dd><aeria-icon icon="clock">12:30 todos os dias</aeria-icon></dd>
    
                <dt><aeria-icon icon="tag-simple">Notificação retroativa de rastreio pra encomendas recém enviadas</aeria-icon></dt>
                <dd><aeria-icon icon="clock">Todo início de hora</aeria-icon></dd>
    
                <dt><aeria-icon icon="tag-simple">Reset de pedidos problemáticos cujo erro já foi resolvido</aeria-icon></dt>
                <dd><aeria-icon icon="clock">00:00, 10:00 e 20:00 horas a cada 4 dias do mês</aeria-icon></dd>
            </dl>
        </collapsible>
        
    
        <collapsible
        title="Formatos"
        isTopic>
            <p class="tw-ml-7">A Arco é escrita em inglês e tem seus elementos traduzidos na parte visual, esses são alguns dos formatos como é internamente:</p>
            <div class="tw-flex tw-flex-col tw-space-y-[-15pt]">
                <collapsible icon="list-dashes" :noMargin="true" title="Status">
                <div class="tw-m-0 tw-pb-4">
                    <div 
                    v-for="status in orderStore.description.properties.status.enum"
                    class="tw-flex tw-flex-row tw-space-x-2 tw-ml-2"
                    >
                        <div>{{ t(status, { capitalize: true }) }}</div>
                        <aeria-icon icon="arrow-right"></aeria-icon>
                        <div>{{ status }}</div>
                    </div>
                </div>
            </collapsible> 
            <collapsible icon="list-dashes" :noMargin="true" :title="t('roles', { capitalize: true })">
                <div class="tw-m-0 tw-pb-4">
                    <div 
                    v-for="role in userStore.description.properties.roles.items.enum"
                    class="tw-flex tw-flex-row tw-space-x-2 tw-ml-2"
                    >
                        <div>{{ t(role, { capitalize: true }) }}</div>
                        <aeria-icon icon="arrow-right"></aeria-icon>
                        <div>{{ role }}</div>
                    </div>
                </div>
            </collapsible> 
            </div>
        </collapsible>
    </div>
</template>

<style scoped>
.icon {
    --icon-color: var(--theme-brand-color-shade-2);
}
</style>