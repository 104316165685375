import type { Shipment } from '../../../api/src/collections/shipment/description'
import { createStore, useStore, createCollectionStore, API_URL } from 'aeria-ui'

export const shipment = createStore((context) => createCollectionStore({
  $id: 'shipment',
  state: {
    generation: {
      limit: 0,
      hoursAgo: 4,
      reshipmentOnly: false,
      groupOrders: true,
    },
    updateTracking: {
      date_min: new Date(),
      date_max: new Date(),
      pay_after_delivery: false
    }
  },
  actions: (state, actions) => ({
    viewContent(filters: Pick<Shipment, '_id'>) {
      const orderStore = useStore('order', context.manager)
      orderStore.filters = {
        $or: [
          { shipment: filters._id },
          { shipment_older: filters._id }
        ],
        ...orderStore.freshFilters
      }

      orderStore.$actions.clearItems()
      ROUTER.push('/dashboard/order/')
    },
    downloadFiles(filters: Pick<Shipment, '_id'>) {
      window.open(`${API_URL}/shipment/downloadFiles/${filters._id}`)
    },
    async downloadLabels(filters: Pick<Shipment, '_id' | 'status'>) {
      if( filters.status === 'pending' ) {
        const metaStore = useStore('meta', context.manager)
        const prompt = await metaStore.$actions.spawnPrompt({
          body: `
            Deseja aproveitar e marcar essa PLP como enviada?
            Após a PLP ser marcada como "enviada", o status não poderá ser revertido sem que todos os pedidos sejam desvinculados da mesma, o que fará com que esses pedidos caiam em outra PLP numa próxima geração de etiquetas.
          `,
          options: {
            no: {
              title: 'Não',
              variant: 'danger',
            },
            yes: {
              title: 'Sim',
            }
          }
        })

        if( prompt.name === 'yes' ) {
          await actions.insert({
            what: {
              _id: filters._id,
              status: 'sent',
            }
          })
        }
      }

      window.open(`${API_URL}/shipment/downloadLabels/${filters._id}`)
    },
    downloadShipment() {

    },
    // markAsSent(payload: any) {
    //   return this.customEffect(
    //     'markAsSent', payload,
    //     this.insertItem
    //   )
    // },
    // markAsCancelled(payload: any) {
    //   return this.customEffect(
    //     'markAsCancelled', payload,
    //     this.insertItem
    //   )
    // },
    updateTracking(filters: Pick<Shipment, '_id'>) {
      actions.custom('updateTracking', {
        _id: filters._id
      })

      const metaStore = useStore('meta', context.manager)
      metaStore.$actions.spawnModal({
        title: 'Sucesso',
        body: 'Mensagem adicionada à fila'
      })
      return 
    },
    make() {
      return aeria.shipment.make.POST(state.generation)
    },
    updateSpanTracking() {
      return aeria.shipment.updateSpanTracking.POST(state.updateTracking)
    },
    getComputedColor(status: string) {
      switch( status ) {
        case 'sent': return 'brand'
        case 'pending': return 'pending'
        default: return 'grey'
      }
    }
    // remake(payload: any) {
    //   return this.custom('remake', payload)
    // }
  })
}, context))
